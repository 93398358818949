import { mapState } from 'vuex';
import GtmEventsMixin from './GtmEventsMixin';

/**
 * Copy/Paste in browser console for manual check
 * SDG.Publisher.getCustomVendorConsent('Axel Springer Teaser Ad GmbH', e => console.log("consent: ", e))
 */

const vendors = [
  {
    name: 'Axel Springer Teaser Ad GmbH',
    value: 'axel springer teaser ad gmbh',
    iabVendorId: 647,
    mutation: 'ads/setAllowAdup'
  },
  {
    name: 'Google Ads',
    value: 'google advertising products',
    iabVendorId: 755,
    mutation: 'ads/setAllowGoogleAds'
  },
  {
    name: 'Google Analytics',
    value: 'google analytics',
    mutation: 'ads/setAllowGoogleAnalytics'
  },
  {
    name: 'Google Optimize',
    value: 'google optimize',
    mutation: 'ads/setAllowGoogleOptimize'
  },
  {
    name: 'Criteo SA',
    value: 'criteo sa',
    iabVendorId: 91,
    mutation: 'ads/setAllowCriteo'
  },
  {
    name: 'RTB House S.A.',
    value: 'rtb house',
    iabVendorId: 16,
    mutation: 'ads/setAllowRTBHouse'
  },
  {
    name: 'Hotjar',
    value: 'hotjar',
    mutation: 'ads/setAllowHotjar'
  },
  {
    name: 'Facebook, Inc.',
    value: 'facebook',
    mutation: 'ads/setAllowFacebook'
  },
  {
    name: 'TikTok',
    value: 'tiktok',
    mutation: 'ads/setAllowTikTok'
  },
  {
    name: 'INFOnline GmbH',
    value: 'infonline gmbh',
    iabVendorId: 730,
    mutation: 'ads/setAllowInfonline'
  },
  {
    name: 'LinkedIn Ads',
    value: 'linkedin ireland unlimited company',
    iabVendorId: 804,
    mutation: 'ads/setAllowLinkedIn'
  },
  {
    name: 'Microsoft Advertising',
    value: 'microsoft advertising',
    mutation: 'ads/setAllowMicrosoft'
  },
  {
    name: 'Shopping Minds bv',
    value: 'shopping minds',
    mutation: 'ads/setAllowShoppingMinds'
  },
  {
    name: 'Selligent CRM System',
    value: 'selligent crm',
    mutation: 'ads/setAllowSelligent'
  },
  {
    name: 'Genesis Media',
    value: 'genesis media',
    mutation: 'ads/setAllowGenesis'
  },
  {
    name: 'Spotify AB',
    value: 'spotify ab',
    mutation: 'ads/setAllowSpotify'
  },
  {
    name: 'AWIN AG',
    value: 'awin ag',
    iabVendorId: 907,
    mutation: 'ads/setAllowAwin'
  }
];

/* global SDG */
/* eslint-disable no-console */
export default {
  mixins: [GtmEventsMixin],
  computed: {
    ...mapState({
      hasSentConsent: (state) => state.gtmEvents.hasSentConsent
    })
  },
  methods: {
    async consentCheck() {
      let consentVendorString = '';
      this.$store.commit('ads/setStroeerIsCheckingConsent', true);
      // eslint-disable-next-line no-console
      console.log('=== Stroeer Consent Check ===');

      for (let vendor of vendors) {
        // Check IAB Vendors
        if (vendor.iabVendorId) {
          await new Promise((resolve) => {
            SDG.Publisher.getIABVendorConsent(vendor.iabVendorId, (e) => {
              if (e === true) {
                this.$store.commit(vendor.mutation, true);
                consentVendorString += `${vendor.value}, `;
              }

              resolve();
            });
          });
        } else {
          // Check NON-IAB Vendors
          const allowVendor = SDG.Publisher.getCustomVendorConsent(vendor.name);
          this.$store.commit(vendor.mutation, allowVendor);
          if (allowVendor) {
            consentVendorString += `${vendor.value}, `;
          }
        }
      }

      if (!this.hasSentConsent) {
        // eslint-disable-next-line no-console
        console.log('=== Send Consent String + Pending After ===');
        // eslint-disable-next-line no-console
        console.log(consentVendorString);
        this.$gtm.push({
          event: 'guru_consent',
          guru_consent_granted: consentVendorString
        });
        this.$store.commit('gtmEvents/setHasSentConsent', true);
        this.sendPendingEvents();
      }
      this.$store.commit('ads/setStroeerIsCheckingConsent', false);
      // TCF INIT
      this.initTCF();
    },
    initTCF() {
      // TCF API Checks
      if (!window.__tcfapi) return;
      window.__tcfapi(
        'addEventListener',
        2,
        function (consentData, callbackSuccessfull) {
          const status = consentData.eventStatus;
          const purposeConsents = consentData.purpose.consents;

          if (
            callbackSuccessfull &&
            (status === 'tcloaded' || status === 'useractioncomplete')
          ) {
            if (
              purposeConsents['1'] === true &&
              purposeConsents['2'] === true &&
              purposeConsents['4'] === true &&
              purposeConsents['7'] === true
            ) {
              window.__tcfapi(
                'removeEventListener',
                2,
                function () {},
                consentData.listenerId
              );
            }
          }
        }
      );
    }
  }
};
